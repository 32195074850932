.header {
    height: 80px;

    .inner {
        height: 100%;
        padding: 0 2rem;

        .wrap {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            max-width: 111rem;
            height: 100%;
            margin: 0 auto;
        }
    }
}

.content {
    position: relative;

    .inner {
        height: 100%;
        padding: 0 2rem;

        .wrap {
            height: 100%;
            max-width: 111rem;
            margin: 0 auto;

            .authForm {
                width: 780px;
                height: 530px;
                margin: 80px auto;
                display: flex;
                flex-direction: row;
                box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.15);;
                overflow: hidden;
                border-radius: 10px;

                .leftSide, .rightSide {
                    position: relative;
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0 50px;
                    overflow: hidden;
                    text-align: center;

                    .form {
                        .row {
                            position: relative;
                            margin-bottom: 12px;

                            h2 {
                                margin-bottom: 18px;
                            }

                            .authSocialTitle {
                                font-size: 14px;
                            }

                            .authSocial {
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                margin-top: 10px;
                                list-style: none;

                                li {
                                   margin-right: 20px;
                                   
                                   &:last-child {
                                       margin-right: 0;
                                   }
                                }
                            }

                            .shortText {
                                font-size: 14px;
                                margin-bottom: 28px;
                            }

                            .forgotLink {
                                color: #4967AE;
                                text-decoration: underline;
                                
                                &:hover {
                                    cursor: pointer;
                                    opacity: .8;
                                }
                            }

                            input {
                                width: 280px;
                                background: #ECECEC;
                                border: 1px solid #FFFFFF;
                                box-sizing: border-box;
                                border-radius: 30px;
                                padding: 14px 18px;
                                font-size: 14px;
                                outline: none;

                                &.labelError {
                                    background-color: #FFE7E7;
                                }
                            }

                            .textError {
                                color: #EC6D70;
                                font-size: 13px;
                                margin-bottom: 18px;
                            }

                            .textSuccess {
                                color: #1aac45;
                                font-size: 13px;
                                margin-bottom: 18px;
                            }

                            .button {
                                display: inline-block;
                                background: linear-gradient(94.51deg, #2991CF 3.96%, #4967AE 79.5%);
                                border: none;
                                box-sizing: border-box;
                                border-radius: 30px;
                                color: #fff;
                                font-size: 16px;
                                padding: 12px 50px;

                                &:hover {
                                    cursor: pointer;
                                    opacity: .8;
                                }
                            }

                            .eye {
                                width: 16px;
                                height: 16px;
                                background: red;
                                display: inline-block;
                                position: absolute;
                                right: 20px;
                                top: 15px;
                                background: url('../../../static/images/hidePassword.png');

                                &:hover {
                                    cursor: pointer;
                                }
                            }

                            .showPassword {
                                background: url('../../../static/images/showPassword.png');
                            }
                        }

                        .mobileVersion {
                            display: none;
                            margin-top: 24px;
                        }
                    }

                    .mask {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: linear-gradient(180deg, #4967AE -1.66%, #2991CF 100%);
                        width: 100%;
                        height: 100%;
                        transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
                        z-index: 100;

                        .maskInner {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;

                            h2 {
                                font-size: 18px;
                                color: #fff;
                                text-align: center;
                                margin-bottom: 20px;
                            }

                            p {
                                font-size: 14px;
                                color: #fff;
                                text-align: center;
                                margin-bottom: 30px;
                            }

                            .button {
                                border: 1px solid #FFFFFF;
                                box-sizing: border-box;
                                border-radius: 30px;
                                text-align: center;
                                color: #fff;
                                padding: 12px 34px;

                                &:hover {
                                    cursor: pointer;
                                    opacity: .8;
                                }
                            }

                            img {
                                margin-top: 30px;
                            }
                        }
                    }

                    .firstState {
                        transform: translateX(100%);
                    }
                    .firstInnerState {
                        transform: translateX(200%);
                    }
                    .secondState {
                        transform: translateX(-100%);
                    }
                    .secondInnerState {
                        transform: translateX(-200%);
                    }
                }
            }
        }

        @media (max-width: 840px) {
            .wrap {
                .authForm {
                    width: 340px;

                    .leftSide, .rightSide {   
                        .form {
                            .row {
                                input {
                                    width: 100%;
                                }
                            }
                            .mobileVersion {
                                display: block;
                            }
                        }
                    }

                    .mobileHide {
                        display: none;
                    }

                    .mobileWidth {
                        width: 100%;
                    }
                }
            }
        }
    }
}
